import { TaxonomyNode, TaxonomyNodeApi } from "types";

import { isString } from "@civicscience/chops";

/**
 * Represents the value of a taxonomy node in the tree.
 * This is a delimited string which contains the full "path" of the Node.
 *
 * example: "verticals.transportation and travel.3651.3652"
 *
 * The full path is done for 2 major reasons:
 *
 * 1. Ensure the value is unique.
 *    The taxonomy intentionally has duplicate nodes under different parents so the ID alone is not unique in the tree.
 * 2. We submit the selected node and all of its parent IDs to the API.
 *    Having the full path in the value makes this easy and saves us from searching the tree.
 */
export type TaxonomyNodeValue = string;

/**
 * The string used to combine each part of the path into a full path for a given node.
 */
const VALUE_DELIMITER = ".";

/**
 * Returns a string that can be used as a `value` for a TaxonomyNode.
 *
 * example: "verticals.transportation and travel.3651.3652"
 */
const encodeTaxonomyNodeValue = (parent: TaxonomyNode | undefined, node: TaxonomyNodeApi): TaxonomyNodeValue =>
  parent
    ? `${parent.value}${VALUE_DELIMITER}${node.taxonomyNodeId ?? node.name.toLocaleLowerCase()}`
    : (node.taxonomyNodeId?.toString() ?? node.name.toLowerCase());

/**
 * Extract the "selected" tag ID from the provided full path tag ID.
 *
 * For example, the tag "3652" may have a full path that looks like this
 *  "verticals.transportation and travel.3651.3652"
 *
 * This function will extract just the final ID, 3652, from this full path string.
 */
const decodeTaxonomyNodeValueSingle = (value: TaxonomyNodeValue): string | null =>
  value
    .split(VALUE_DELIMITER)
    .filter((x) => /^\d+$/.test(x))
    .reverse()[0];

/**
 * Builds a list of distinct Node IDs from an array of TaxonomyNodeValue instances.
 *
 * ["verticals.retail.3483.3486", "verticals.retail.3483.3487"] -> ["3483", "3486", "3487"]
 */
const buildTaxonomyNodeApiValues = (selections: TaxonomyNodeValue[]): string[] => {
  const allIds = selections.map(decodeTaxonomyNodeValueSingle).filter(isString);
  return Array.from(new Set(allIds));
};

export default {
  encodeValue: encodeTaxonomyNodeValue,
  buildApiValues: buildTaxonomyNodeApiValues,
};
