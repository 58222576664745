import { Account, User, UserPasswordUpdate } from "types";
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "react-query";

import { ProfileAPI } from "api";

const queryKeys = {
  all: () => ["users"] as const,
  details: () => [queryKeys.all, "detail"] as const,
  detail: (id: number) => [queryKeys.details, id] as const,
  userAccounts: () => ["user-accounts"] as const,
};

/**
 * TODO: convert rest of profile endpoints
 */

/**
 * Fetches all accounts a user belongs to.
 * Majority of the time a user belongs to just one.
 */
export const useGetUser = <T = User>(options: UseQueryOptions<User, unknown, T> = {}) =>
  useQuery<User, unknown, T>(queryKeys.details(), () => ProfileAPI.get(), options);

/**
 * Fetches all accounts a user belongs to.
 * Majority of the time a user belongs to just one.
 */
export const useGetUserAccounts = <T = Account[]>(options: UseQueryOptions<Account[], unknown, T> = {}) =>
  useQuery<Account[], unknown, T>(queryKeys.userAccounts(), () => ProfileAPI.getAccounts(), options);

/**
 * Update user password.
 */
export const useUpdatePassword = (options: UseMutationOptions<void, unknown, UserPasswordUpdate>) =>
  useMutation((args) => ProfileAPI.updatePassword(args), options);
