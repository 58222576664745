import { identifyAppcues, pushToDataLayer } from "utils";

import { User } from "types";

/**
 * Send user specific initialization to analytics platforms
 */
const bootAnalytics = (user: User): void => {
  const { userId, accountRefid: accountId, isImpersonated, isSysAdmin, isInternal, account } = user;

  // GTM
  pushToDataLayer({ userId, accountId, isImpersonated, isSysAdmin, isInternal });

  // APPCUES
  identifyAppcues(user, account);
};

export default bootAnalytics;
