import { CommonFilterTerms, SortTypes } from "consts";

const { ORDER_ASC, ORDER_DESC } = CommonFilterTerms;

export { SortTypes } from "consts";

/**
 * A set of default values for common sort types.
 * We use these as the basis for the `makeSortItem` function below.
 */
const defaultSortDefinitions = {
  [SortTypes.NAME]: { text: "Name", value: SortTypes.NAME, apiValue: { order: ORDER_ASC, orderBy: "name" } },
  [SortTypes.NAME_ASC]: {
    text: "Name ascending (a-z)",
    value: SortTypes.NAME_ASC,
    apiValue: { order: ORDER_ASC, orderBy: "name" },
  },
  [SortTypes.NAME_DESC]: {
    text: "Name descending (z-a)",
    value: SortTypes.NAME_DESC,
    apiValue: { order: ORDER_DESC, orderBy: "name" },
  },
  [SortTypes.NEWEST_CREATED]: {
    text: "Newest first",
    value: SortTypes.NEWEST_CREATED,
    apiValue: { order: ORDER_DESC, orderBy: "created" },
  },
  [SortTypes.OLDEST_CREATED]: {
    text: "Oldest first",
    value: SortTypes.OLDEST_CREATED,
    apiValue: { order: ORDER_ASC, orderBy: "created" },
  },
  [SortTypes.NEWEST_UPDATED]: {
    text: "Newest first",
    value: SortTypes.NEWEST_UPDATED,
    apiValue: { order: ORDER_DESC, orderBy: "updated" },
  },
  [SortTypes.OLDEST_UPDATED]: {
    text: "Oldest first",
    value: SortTypes.OLDEST_UPDATED,
    apiValue: { order: ORDER_ASC, orderBy: "updated" },
  },
  [SortTypes.RELEVANCE]: {
    text: "Relevance",
    value: SortTypes.RELEVANCE,
    apiValue: { order: null, orderBy: null },
  },
  [SortTypes.RESPONSES]: {
    text: "Most responses",
    value: SortTypes.RESPONSES,
    apiValue: { order: ORDER_DESC, orderBy: "response_count" },
  },
  [SortTypes.RESPONSES_DESC]: {
    text: "Most responses",
    value: SortTypes.RESPONSES_DESC,
    apiValue: { order: ORDER_DESC, orderBy: "response_count" },
  },
  [SortTypes.RESPONSES_ASC]: {
    text: "Least responses",
    value: SortTypes.RESPONSES_ASC,
    apiValue: { order: ORDER_ASC, orderBy: "response_count" },
  },
  [SortTypes.STRENGTH_OF_ASSOCIATION]: {
    text: "Strength of Association",
    value: SortTypes.STRENGTH_OF_ASSOCIATION,
    apiValue: { order: ORDER_DESC, orderBy: "t_value" },
  },
};

/**
 * Builds an instance of an object that is used to define a sort option.
 * This includes keys for `text`, `value`, and `apiValue`.
 * The apiValue is an object whose key/values represent what will be sent to the API for that option.
 * This is typically an `order` and `orderBy` property.
 *
 * @example
 *
 * makeSortItem(SortTypes.NAME)
 * // => { text: "Name", value: "name", apiValue: { order: "asc" , orderBy: "name" } }
 *
 * makeSortItem(SortTypes.NAME, { apiValue: { orderBy: "question.name" } })
 * // => { text: "Name", value: "name", apiValue: { order: "asc" , orderBy: "question.name" } }
 *
 * @param {string} sortType one of the `SortType` options.
 * @param {Object} overrides keys that override default options above.
 * @returns {Object} a sort definition object
 */
export const makeSortItem = (sortType, overrides = {}) => {
  const defaultDef = defaultSortDefinitions[sortType] ?? {};
  return {
    ...defaultDef,
    ...overrides,
    apiValue: {
      ...(defaultDef?.apiValue ?? {}),
      ...overrides?.apiValue,
    },
  };
};

/**
 * Creates both `sortOptions` and a `sortLookup` function based on the provided params.
 * The `sortLookup` is useful for mapping the value selected in the UI to the values that are sent to the API.
 *
 * @example
 * const { sortOptions, sortLookup } = createSorting([
 *  makeSortItem(SortTypes.NAME),
 *  makeSortItem(_CREATED, { apiValue: { orderBy: 'created_time' } }),
 *  makeSortItem(SortTypes.OLDEST_CREATED, { apiValue: { orderBy: 'created_time' } }),
 *  { text: "Custom", value: "custom", apiValue: { order: "asc" , orderBy: "custom" } },
 * ]);
 *
 * @param {Array} sortItems an array of sort option objects `{ text, value, apiValue }`.
 * @returns {{ sortOptions: Array, sortLookup: Function }}
 */
export const createSorting = (sortItems) => {
  return {
    sortOptions: sortItems.map(({ text, value }) => ({ text, value })),
    sortLookup: (val) => sortItems.find((x) => x.value === val)?.apiValue ?? {},
  };
};
