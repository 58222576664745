import React, { ComponentPropsWithRef } from "react";

import { Dropdown } from "react-bootstrap";
import classNames from "classnames";

type MenuProps = ComponentPropsWithRef<typeof Dropdown.Menu>;

type CSDropdownMenuProps = MenuProps & {
  border?: boolean;
  borderClass?: string;
  shadow?: boolean;
  shadowClass?: string;
  rounded?: boolean;
  roundedClass?: string;
};

const CS_DROPDOWN_BORDER_CLASS = "border border-1";
const CS_DROPDOWN_SHADOW_CLASS = "shadow shadow-1";
const CS_DROPDOWN_ROUNDED_CLASS = "rounded rounded-1";

/**
 * Renders a context menu with the provided menu items.
 */
const CSDropdownMenu = ({
  className,
  align = "end",
  border = true,
  borderClass = CS_DROPDOWN_BORDER_CLASS,
  shadow = true,
  shadowClass = CS_DROPDOWN_SHADOW_CLASS,
  rounded = true,
  roundedClass = CS_DROPDOWN_ROUNDED_CLASS,
  flip = false,
  children,
  ...restMenuProps
}: CSDropdownMenuProps) => {
  const borderClassWrapper = border ? (borderClass ?? CS_DROPDOWN_BORDER_CLASS) : undefined;
  const shadowClassWrapper = shadow ? (shadowClass ?? CS_DROPDOWN_SHADOW_CLASS) : undefined;
  const roundedClassWrapper = rounded ? (roundedClass ?? CS_DROPDOWN_ROUNDED_CLASS) : undefined;

  return (
    <Dropdown.Menu
      {...restMenuProps}
      align={align}
      className={classNames(
        "csa-dropdown-menu",
        className,
        shadowClassWrapper,
        borderClassWrapper,
        roundedClassWrapper,
      )}
      flip={flip}
    >
      {children}
    </Dropdown.Menu>
  );
};

export default CSDropdownMenu;
