import { pushToDataLayer, trackAppcuesEvent } from "utils";

type TrackEventProps = {
  name: string;
  url?: string;
  metadata?: Record<string, unknown> | null;
};
const trackEvent = ({ name, url = window.location.pathname, metadata = null }: TrackEventProps): void => {
  trackAppcuesEvent(name, url);

  // GTM
  pushToDataLayer({
    event: name,
    eventProps: {
      metadata,
      // category: category,
      // action: action,
      // label: label,
      // value: value
    },
  });
};

export default trackEvent;
